import * as React from "react";
import '../styles/introduction.scss';

import { smoothScrollTo } from '../utils/utils.js'

// markup
const Introduction = ({introductionReference, projectsReference, projectButtonReference, sendButtonRef}) => {
  return (
    <div className="introduction" ref={introductionReference}>
      <h1 className="introduction__title">
        Computer Science Student at the University of Gothenburg
      </h1>
      <h2 className="introduction__subtitle">
        Joachim Ørfeldt Pedersen
      </h2>
      <p className="introduction__description">
        A manifestation, an urge that is not easily tamed, is exploring the potential of a good user experience. 
        Not to mention the interest in researching efficient algorithms for the upcoming projects, adapting the project to the correct framework and programming language. And how should this be achieved? A personal preference: an Agile development cycle.
      </p>

      <button className="introduction__cta-button" onClick={
        ()=>{ smoothScrollTo(projectsReference) }
      }>
        See selected work
      </button>
    </div>
  )
}

export default Introduction