import React, {useRef} from "react"

import Navigation from './Navigation'
import Introduction from './Introduction'
import Projects from './Projects'
import Contact from './Contact'

import '../styles/styles.scss'
import '../styles/root.scss'

// markup
const IndexPage = () => {
  let introductionReference = useRef(null);
  let projectsReference = useRef(null);
  let contactReference = useRef(null);

    return (
    <div className="root"> 
        <div className="root__entry">
          <Introduction 
            projectsReference={projectsReference} 
            introductionReference={introductionReference} 
          />
          <Projects projectsReference={projectsReference} />
          <Contact contactReference={contactReference} />
        </div>

         <div className="root__entry">
          <Navigation 
            introductionReference={introductionReference} 
            projectsReference={projectsReference}
            contactReference={contactReference}
          />
        </div>
    </div>
  )
}

export default IndexPage
